import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from 'gatsby-background-image' 

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import '../main.scss'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/** @jsx jsx */
import { css, jsx } from '@emotion/core'



// images
import loewsBkg from "../img/Loews-Logo-new.png"
import magazineAd from '../img/Magazine-Ad.jpg'
import tfrByNumbers from "../img/TFR-by-Numbers-v2.jpg"
import brandPositioning from '../img/Brand-Positioning-v2.jpg'
import topOfIpad from '../img/Top-of-Ipad.jpg'
import ipadAndIphone from '../img/Ipad-and-Phone.jpg'
import tiledAds from '../img/Tiled-Ads.jpg'
import loewsAdLarge from '../img/Loews-Ad-Large.jpg'
import adsSmall from '../img/Ads-Small.jpg'


const LoewsPage = () => {
    const slickSettings = {
        dots:true,
        infinite:true,
        speed:500,
        slidesToShow:1,
        slidesToScroll:1
    }
    return (
    <Layout>
        <SEO title="Loews" />
        <div className="all">
            <div id="cases">
            <div id="loews" className="case">
                <div className="close"><Link to="/">Go Back Home</Link></div>
                    <div className="mast shift">
                        <img src={loewsBkg} alt="Loews" />
                        <h1><span>Loews</span></h1>
                        <h2>A unique campaign lets guests do the talking.</h2>
                    </div>
                    <div className="intro">
                        <p>Coming off a strong year, Loews Hotels &amp; Resorts was in search of the next big thing to increase awareness and consideration of the brand, while driving direct bookings by connecting with the target audience on a human level. With a limited share of voice in a crowded field, Catch sought to break Loews through the "sea of sameness" by showcasing a more genuine guest experience across the brand, in a way that none of its competition could match. The result: Travel for Real, a first-in-category campaign that sources guest-generated social media imagery. Because, as we like to say, nobody tells the Loews story like the guests themselves.</p>
                    </div>
                    <div className="work">
                        <ul>
                            <li>
                                <div className="section3">
                                    <div className="video">
                                        {/*<iframe src="https://player.vimeo.com/video/156712446" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>*/}
                                        <style dangerouslySetInnerHTML={{ __html: ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }" }} /><div className="embed-container"><iframe src="https://player.vimeo.com/video/156712446" frameBorder={0} webkitallowfullscreen mozallowfullscreen allowFullScreen /></div>
                                        {/*<video preload="false" controls poster="/vid/vid-screen.jpg">
											<source src="/vid/Catch_Loews.mp4" type="video/mp4" />
											<source src="/vid/Catch_Loews.webm" type="video/webm" />
											<source src="/vid/Catch_Loews.ogv" type="video/ogg" />
										</video>*/}
                                    </div>
                                </div>
                            </li>
                            <li className="spaceWhite"><img src={tfrByNumbers} alt="" /></li>
                            <li><img src={magazineAd} alt="" /></li>
                            <li className="spaceWhite"><div className="intro textOverwrite"><p>As the brand's first fully integrated campaign across all marketing touch points, Travel For Real gives Loews its best platform yet to achieve a cohesive brand positioning that clearly differentiates it within the category.</p></div></li>
                        </ul>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={tiledAds} alt="tiled ads" /></li>
                        </ul>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={brandPositioning} alt="" /></li>
                            <li><img src={topOfIpad} alt="" /></li>
                            <li><img src={ipadAndIphone} alt="" /></li>
                        </ul>
                    </div>
                    <div className="testmonial">
                        <p>"There are no tricks... 'Travel For Real' is the real deal, through the eyes, and lenses, of our guests who loved the time they had at Loews." </p>
                        <span>Doug Spitzer<br /> Chief Creative Officer, Catch NY</span>
                    </div>
                    <div className="work">
                        <ul>
                            <li><img src={loewsAdLarge} alt="" /></li>
                            <li><img src={adsSmall} alt="" /></li>
                        </ul>
                    </div>
                </div>{/*//loews*/}
            </div>
        </div>{/* #all */}
    </Layout>
)}

export default LoewsPage
